<template>
  <el-dialog
    :visible="visible"
    :title="dialogTitle"
    width="600px"
    class="combo-dialog"
    @opened="afterOpen"
    @close="onClose"
  >
    <div class="scroll-wrap">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="商品分类" prop="goodsType">
          <el-select
            v-model="form.goodsType"
            placeholder="请选择"
            size="small"
            :rules="[{ required: true, message: '商品分类不能为空' }]"
            style="width: 320px"
            :disabled="mode === 'edit'"
          >
            <el-option
              v-for="item in dict.GoodsTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品品牌"
          prop="brandName"
          :rules="[{ required: true, message: '商品品牌不能为空' }]"
        >
          <el-input
            v-model="form.brandName"
            size="small"
            placeholder="请输入"
            style="width: 320px"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { EnumGoodsType, GoodsTypeList } from "@/enum/dict/index.js";
import { addGoodsBrand, editGoodsBrand } from "./api.js";

export default {
  name: "GoodsBrandDetail",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: true,
      validator: (value) => ["create", "edit"].includes(value),
    },
    goodsType: {
      type: Number,
      required: false,
      default: null,
    },
    brandName: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: null,
        brandName: "",
        goodsType: EnumGoodsType.C3,
      },
      dict: { GoodsTypeList },
    };
  },
  computed: {
    dialogTitle() {
      switch (this.mode) {
        case "create":
          return "新增品牌";
        case "edit":
          return "编辑品牌";
        default:
          return "";
      }
    },
  },
  methods: {
    async afterOpen() {
      if (this.mode === "create") {
        this.$refs.form.resetFields();

        this.$set(this, "form", this.$options.data().form);
        return;
      }
      this.form.id = this.id;
      this.form.brandName = this.brandName;
      this.form.goodsType = this.goodsType;
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      console.log(this.id);

      if (this.form.id) {
        await editGoodsBrand(this.form);
      } else {
        await addGoodsBrand(this.form);
      }

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.onClose();

      this.$emit("refreshList");
    },
  },
};
</script>
<style scoped lang="scss">
.combo-dialog {
  /deep/ .el-form-item__label {
    font-weight: bold;
  }
}

.scroll-wrap {
  max-height: 70vh;
  overflow-y: auto;
}

.combo-form-item {
  width: 300px;

  /deep/ input {
    text-align: left;
  }
}

.repay-wrap {
  .repay-title {
    width: 120px;
    line-height: 40px;
    padding-right: 12px;
    text-align: right;
    font-weight: bold;
  }

  .repay-form {
    padding: 0 50px;
  }

  .repay-form-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 14px 0;

    /deep/ .el-form-item {
      margin: 0;
    }

    &:first-child {
      border-top: 1px solid #ddd;
    }
  }
}
</style>
