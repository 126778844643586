<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name" />
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商品品牌">
          <el-input
            v-model="searchData.brandName"
            size="small"
            placeholder="请输入"
            class="search-form-item"
          />
        </el-form-item>
        <el-form-item label="商品分类">
          <el-select
            v-model="searchData.goodsType"
            placeholder="请选择"
            size="small"
            clearable
            class="search-form-item"
          >
            <el-option
              v-for="item in dict.GoodsTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="create-combo">
      <el-button
        type="primary"
        size="small"
        @click="openDialog('create')"
        :disabled="!isShowBtn(AUTH_BTN.goods_brand_create)"
        >新增品牌</el-button
      >
    </div>
    <tp-table
      :tableData="table.data"
      :totalNum="table.total"
      :current-page.sync="table.currentPage"
      :pageSize.sync="table.pageSize"
    >
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="brandName" label="商品品牌" />
      <el-table-column prop="goodsType" label="商品分类">
        <template slot-scope="scope">
          <span>{{ dict.GoodsTypeToTextMap.get(scope.row.goodsType) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <div class="operations">
            <el-link
              type="primary"
              @click="
                openDialog('edit', {
                  id: scope.row.id,
                  goodsType: scope.row.goodsType,
                  brandName: scope.row.brandName,
                })
              "
              :underline="false"
              v-if="!scope.row.status"
              :disabled="!isShowBtn(AUTH_BTN.goods_brand_edit)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              @click="onDelete(scope.row)"
              :underline="false"
              v-if="!scope.row.status"
              :disabled="!isShowBtn(AUTH_BTN.goods_brand_delete)"
              >删除</el-link
            >
          </div>
        </template>
      </el-table-column>
    </tp-table>
    <goods-brand-detail
      :visible.sync="dialogConfig.visible"
      :id="dialogConfig.id"
      :mode="dialogConfig.mode"
      :brandName="dialogConfig.brandName"
      :goodsType="dialogConfig.goodsType"
      @refreshList="getList"
    />
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
  EnumGoodsType,
  GoodsTypeList,
  GoodsTypeToTextMap,
} from "@/enum/dict/index.js";

import { getGoodsBrandPage, deleteGoodsBrand } from "./api.js";
import GoodsBrandDetail from "./detail.vue";

export default {
  name: "GoodsBrandList",
  mixins: [authBtnMixin],
  components: {
    GoodsBrandDetail,
  },
  data() {
    return {
      searchData: {
        brandName: undefined,
        goodsType: undefined,
      },
      dict: {
        EnumGoodsType,
        GoodsTypeToTextMap,
        GoodsTypeList,
      },
      table: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        data: [],
      },
      dialogConfig: {
        mode: "create",
        id: null,
        visible: false,
        goodsType: null,
        brandName: null,
      },
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    "table.currentPage"() {
      this.getList();
    },
    "page.pageSize"() {
      this.getList();
    },
    "searchData.status"() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const res = await getGoodsBrandPage({
        ...this.searchData,
        page: this.table.currentPage,
        pageSize: this.table.pageSize,
      });

      this.table.data = res.list || [];
      this.table.total = res.total || 0;
    },
    reset() {
      this.$set(this, "searchData", this.$options.data().searchData);

      this.getList();
    },
    async onDelete(row) {
      await this.$confirm("删除商品品牌后无法恢复，是否继续?");

      await deleteGoodsBrand({
        id: row.id,
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.getList();
    },
    openDialog(mode, detail) {
      this.dialogConfig = { mode, visible: true, ...detail };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}

.search-form-item {
  width: 220px;

  /deep/ {
    input {
      text-align: left;
    }
  }
}

.create-combo {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.operations {
  display: flex;
  gap: 8px;
}
</style>
