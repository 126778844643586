import { http } from "@/http/axios.js";
// 获取商品品牌列表
export function getGoodsBrandPage(params) {
  return http({
    url: "/admin/oprt/goodsBrand/page",
    method: "get",
    params,
  });
}

// 获取商品品牌详情
export function getGoodsBrandDetail(id) {
  return http({
    url: "/admin/oprt/goodsBrand/detail",
    method: "get",
    params: { id },
  });
}

// 新增商品品牌
export function addGoodsBrand(data) {
  return http({
    url: "/admin/oprt/goodsBrand/add",
    method: "post",
    data,
  });
}

// 新增商品品牌
export function editGoodsBrand(data) {
  return http({
    url: "/admin/oprt/goodsBrand/edit",
    method: "post",
    data,
  });
}

// 删除商品品牌
export function deleteGoodsBrand(data) {
  return http({
    url: "/admin/oprt/goodsBrand/delete",
    method: "post",
    data,
  });
}
